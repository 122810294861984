<template>
	<div class="radio-button">
		<input
			class="radio-button__option"
			type="radio"
			:id="data.value"
			:checked="value === data.value"
			:name="data.name && data.name"
			@input="$emit('input', data.value)"
		/>
		<label class="radio-button__option-label" :for="data.value">{{ data.label }}</label>
	</div>
</template>

<script>
export default {
	name: 'FilterRadioButton',

	props: ['value', 'data']
};
</script>

<style lang="scss">
$component: 'radio-button';

.#{$component} {
	display: flex;

	width: 100%;
	max-width: 320px;
	height: 24px;

	margin-bottom: 16px;

	overflow: hidden;

	&__option {
		position: absolute;

		visibility: hidden;
		opacity: 0;
		width: 0;
		height: 0;

		&:checked,
		&--checked {
			visibility: visible;
			opacity: 1;
		}

		&:checked + label,
		&--checked + label {
			color: $color-white;

			&:before {
				display: block;
				background-color: rgba(var(--primary-color), 1);
				box-shadow: inset 0px 0px 0px 6px $color-black;
			}
		}
	}

	&__option-label {
		cursor: pointer;
		position: relative;

		display: flex;
		justify-content: flex-start;
		align-items: center;

		width: 100%;
		height: 100%;

		font-size: 16px;

		color: $color-white;
		text-transform: capitalize;

		&:before {
			content: '';

			position: relative;
			display: block;

			width: 24px;
			height: 100%;
			left: 0;

			margin-right: 11px;

			background-color: rgba(var(--primary-color), 0);
			border: 1px solid #ffffff;
			border-radius: 50%;

			transition: all 0.5s $ease-in-out-cubic;
		}
	}
}
</style>
