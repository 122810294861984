<template>
	<div class="basket-prereg-save">
		<transition name="fade-fast" mode="out-in">
			<Button v-if="wishlistState === 'notSaved'" class="button--alternative-second" @click.native="onClickSave">
				<span>{{ $t('wish_list.checkout.save.title') }}</span>
			</Button>

			<Spinner v-else-if="wishlistState === 'saving'" />
			<div v-else-if="wishlistState === 'saved'" class="basket-prereg-save__complete">
				<div v-html="$t('wish_list.checkout.save.lead')"></div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	name: 'BasketPreregSave',

	data() {
		return {
			isSaving: false
		};
	},

	computed: {
		isWishlistSaved() {
			return this.$store.getters.getIsWishlistSaved;
		},

		wishlistState() {
			if (this.isWishlistSaved) {
				return 'saved';
			} else if (this.isSaving) {
				return 'saving';
			}
			return 'notSaved';
		}
	},

	watch: {
		isWishlistSaved(val) {
			if (val) {
				this.isSaving = false;
			}
		}
	},

	methods: {
		onClickSave() {
			this.isSaving = true;

			setTimeout(() => {
				this.$store.commit('setIsWishlistSaved', true);
			}, 1000);
		}
	}
};
</script>

<style lang="scss">
$component: 'basket-prereg-save';

.#{$component} {
	display: flex;
	align-items: center;
	height: 100%;

	&__complete {
		font-size: 16px;
		color: var(--primary-body-color);
	}
}
</style>
