<template>
	<Grid :fullWidth="true" class="prefill-cart-item">
		<template v-if="data.key != 'travel'">
			<Column :s="isWishlistMode ? 9 : 6" :m="isWishlistMode ? 6 : 4" noMargin>
				<span v-if="data.key == 'transportation'">
					<template v-if="data.data.type.id && data.data.type.id.search('partybussen') == -1">
						<span
							class="prefill-cart-item__list-value"
							v-if="data.data.type.id.split('_')[0] === 'paylogic'"
						>
							{{ getCountry(data.data) }}, {{ data.data.type.name.split('_')[1] }},
							{{ data.data.type.name.split('_')[2] }}
						</span>
					</template>

					<span class="prefill-cart-item__list-value" v-else-if="data.data">{{ data.data.type.name }}</span>

					<span class="prefill-cart-item__list-value" v-else>{{ data.type.name }}</span>
				</span>

				<span v-else-if="data.key != 'reference'">
					<span class="prefill-cart-item__list-value" v-if="data.key != 'merchandise'">
						{{ data.data.type.name }}
					</span>

					<span class="prefill-cart-item__list-value" v-else>{{ getMerchandiseWithSize(data.data) }}</span>
				</span>
			</Column>

			<Column :sHide="true" :m="2">
				<span class="prefill-cart-item__list-value">{{ data.data.numproducts }}x</span>

				<span
					class="prefill-cart-item__list-value"
					v-if="data.data.type.mappedType == 'ticket' && data.data.numproducts >= maxTickets()"
				>
					( Max {{ maxTickets() }} p.p.)
				</span>
			</Column>

			<Column :sHide="true" :m="2">
				<span class="prefill-cart-item__list-value">{{ data.data.type.price | formatPrice }}</span>
			</Column>

			<!-- <Column :sHide="true" :m="1" v-if="showDetailedServiceFees">
				<span class="prefill-cart-item__list-value">{{ data.data.type.serviceCosts | formatPrice }}</span>
			</Column> -->

			<!-- <Column :sHide="true" :m="2">
                <span class="prefill-cart-item__list-value" v-if="data.key != 'insurance'">{{data.data.type.price * data.data.numproducts | formatPrice}}</span>
                <span class="prefill-cart-item__list-value" v-else>{{getInsurencePrice | formatPrice}}</span>
            </Column> -->

			<Column :s="3" :m="2">
				<span class="prefill-cart-item__list-value prefill-cart-item__list-value--white">
					{{ (data.data.type.price * data.data.numproducts) | formatPrice }}
				</span>
			</Column>

			<Column
				v-if="!isWishlistMode && !data.data.type.soldOut && !data.data.type.stockAvailable <= 0"
				:s="3"
				:m="2"
				:noMargin="true"
			>
				<div v-if="!shopUpdateState">
					<template v-if="data.key === 'ticket' && shopConfig.travelsale">
						<Button
							v-if="!apiResponse"
							class="button--ini-mini-height button--alternative"
							@click.native="gotoRoute($event, 'travel')"
						>
							{{ $t('label.cart.add') }}
						</Button>
					</template>

					<template v-else>
						<Button
							v-if="!apiResponse"
							class="button--ini-mini-height button--alternative"
							@click.native="addToCart()"
						>
							{{ $t('label.cart.add') }}
						</Button>

						<div
							v-if="apiResponse && !hasError"
							class="button button--disabled button--ini-mini-height button--alternative-green"
						>
							<span>{{ apiResponse }}</span>
						</div>

						<Button
							v-if="apiResponse && hasError"
							class="button--ini-mini-height button--alternative-third"
							@click.native="gotoRoute"
						>
							{{ $t('label.cart.replace_item') }}
						</Button>
					</template>
				</div>

				<Spinner class="prefill-cart-item__spinner" v-else />
			</Column>

			<Column v-else-if="!isWishlistMode" :s="3" :m="2" :noMargin="true">
				<template v-if="data.data.type.slug !== 'orderfee'">
					<Button
						class="button--ini-mini-height button--alternative-third"
						@click.native="gotoRoute"
						v-if="data.key !== 'ticket'"
					>
						{{ $t('label.cart.replace_item') }}
					</Button>

					<Button
						class="button--ini-mini-height button--alternative-third"
						@click.native="gotoRoute"
						v-else-if="!shopConfig.travelsale"
					>
						{{ $t('label.cart.replace_item') }}
					</Button>
				</template>
			</Column>

			<Column no-margin>
				<div v-if="data.key != 'ticket'" class="prefill-cart-item__error">
					<span v-if="failedResponse && hasError" class="prefill-cart-item__list-value">
						{{ failedResponse }}
					</span>

					<span v-else-if="data.data.type.soldOut" class="prefill-cart-item__list-value">
						{{ $t('label.cart.sold_out') }}
					</span>

					<span v-else-if="data.data.type.stockAvailable <= 0" class="prefill-cart-item__list-value">
						{{ $t('alert.no_stock') }}
					</span>
				</div>

				<div v-else>
					<div class="prefill-cart-item__error" v-if="shopConfig.travelsale">
						<span class="prefill-cart-item__list-value">
							Ticket only available from March 7, 13:00 CET. Travel & Stay packages now available.
						</span>
					</div>

					<div class="prefill-cart-item__error" v-else>
						<span v-if="failedResponse && hasError" class="prefill-cart-item__list-value">
							{{ failedResponse }}
						</span>

						<span v-else-if="data.data.type.soldOut" class="prefill-cart-item__list-value">
							{{ $t('label.cart.sold_out') }}
						</span>

						<span v-else-if="data.data.type.stockAvailable <= 0" class="prefill-cart-item__list-value">
							{{ $t('alert.no_stock') }}
						</span>
					</div>
				</div>
			</Column>
		</template>

		<template v-else>
			<Column :s="isWishlistMode ? 9 : 6" :m="isWishlistMode ? 6 : 4" noMargin>
				<span class="prefill-cart-item__list-value">{{ data.data.package.name }}</span>
			</Column>

			<Column :sHide="true" :m="2">
				<span class="prefill-cart-item__list-value">{{ data.data.package.occupation }}x</span>
			</Column>

			<Column :sHide="true" :m="2">
				<span class="prefill-cart-item__list-value">
					{{ (travelTotalPriceWithTickets / data.data.package.occupation) | formatPrice }}
				</span>
			</Column>

			<!-- <Column :sHide="true" :m="2">
                <span class="prefill-cart-item__list-value">{{travelTotalPriceWithTickets | formatPrice}}</span>
            </Column> -->

			<Column :s="3" :m="2">
				<span class="prefill-cart-item__list-value prefill-cart-item__list-value--white">
					{{ travelSubtotal | formatPrice }}
				</span>
			</Column>

			<Column :s="3" :m="2" v-if="!isWishlistMode && !data.data.package.state.soldout" :noMargin="true">
				<div v-if="!shopUpdateState">
					<Button
						v-if="!apiResponse"
						class="button--ini-mini-height button--alternative"
						@click.native="loadTravel"
					>
						<span>{{ $t('label.cart.add') }}</span>
					</Button>

					<div
						v-if="apiResponse && !hasError"
						class="button button--disabled button--ini-mini-height button--alternative-green"
					>
						{{ apiResponse }}
					</div>

					<Button
						v-if="apiResponse && hasError"
						class="button--ini-mini-height button--alternative-third"
						@click.native="gotoRoute"
					>
						{{ $t('label.cart.replace_item') }}
					</Button>
				</div>

				<Spinner class="prefill-cart-item__spinner" v-else />
			</Column>

			<Column :s="3" :m="2" v-else-if="!isWishlistMode" :noMargin="true">
				<Button class="button--ini-mini-height button--alternative-third" @click.native="gotoRoute">
					{{ $t('label.cart.replace_item') }}
				</Button>
			</Column>

			<Grid
				:fullWidth="true"
				class="prefill-cart-item__alternative"
				v-for="(ticket, index) in travelEntrancetickets"
				:key="'ticket' + index"
			>
				<Column :s="6" :m="4" noMargin>
					<span class="prefill-cart-item__list-value">{{ ticket.type.name }}</span>
				</Column>

				<Column :sHide="true" :s="3" :m="2">
					<span class="prefill-cart-item__list-value">{{ ticket.numproducts }}x</span>
				</Column>

				<Column :sHide="true" :s="3" :m="2">
					<span class="prefill-cart-item__list-value">{{ $t('label.included') }}</span>
				</Column>
			</Grid>

			<Grid
				:fullWidth="true"
				class="prefill-cart-item__alternative"
				v-for="item in data.data.extras"
				:key="item.extra.name"
			>
				<Column :s="6" :m="4" noMargin>
					<span class="prefill-cart-item__list-value">{{ item.extra.name }}</span>
				</Column>

				<Column :sHide="true" :m="2">
					<span class="prefill-cart-item__list-value">{{ item.amount }}x</span>
				</Column>

				<Column :sHide="true" :m="2">
					<span class="prefill-cart-item__list-value">{{ (item.subtotal / item.amount) | formatPrice }}</span>
				</Column>

				<Column :sHide="true" :m="2">
					<span class="prefill-cart-item__list-value">{{ item.subtotal | formatPrice }}</span>
				</Column>
			</Grid>

			<div class="prefill-cart-item__error">
				<span v-if="failedResponse && hasError" class="prefill-cart-item__list-value">
					{{ failedResponse }}
				</span>
			</div>
		</template>
	</Grid>
</template>

<script>
import countryList from '@/static/data/countryCodes.json';
import PrefillCartItem from '@/components/ui/PrefillCartItem';

export default {
	name: 'PrefillCartItem',

	data() {
		return {
			countryList: countryList,
			apiResponse: null,
			failedResponse: null,
			hasError: false,
			cartCheckoutErrorSeen: false
		};
	},

	components: {
		PrefillCartItem
	},

	props: {
		data: {
			data: Object,
			key: String,
			sortedProductWishList: Object
		}
	},

	computed: {
		shopUpdateState() {
			return this.$store.getters['shopapi/getShopUpdateState'];
		},

		getMerchandise() {
			return this.$store.getters['shopapi/getProducts'].merchandise;
		},

		shopConfig() {
			return this.$store.getters['shopconfig/getData'];
		},

		isWishlistMode() {
			return this.shopConfig.preregistration;
		},

		shopCart() {
			return this.$store.getters['shopconfig/getCart'];
		},

		shopTravelCart() {
			return this.$store.getters['shopconfig/getTravelCart'];
		},

		showDetailedServiceFees() {
			return this.shopConfig.showDetailedServiceFees;
		},

		travelTotalPriceWithTickets() {
			const travelProduct = this.data.data;
			const travelTickets = this.travelEntrancetickets[0];
			const totalTicketPrice = travelTickets ? travelTickets.type.price * travelProduct.package.occupation : 0;

			return travelProduct.packageOption.subtotal + totalTicketPrice;
		},

		travelEntrancetickets() {
			const travelTickets =
				!!this.data.sortedProductWishList.ticket &&
				this.data.sortedProductWishList.ticket.find(item => {
					return (
						item.type.categories &&
						item.type.categories.find(
							ticket => ticket.en === 'TRAVEL TICKETS' || ticket.nl === 'TRAVEL TICKETS'
						)
					);
				});

			return travelTickets ? [travelTickets] : [];
		},

		travelSubtotal() {
			const ticketPrice = this.travelEntrancetickets.length ? this.travelEntrancetickets[0].type.price : 0;

			return this.data.data.totalPrice + ticketPrice * this.data.data.package.occupation;
		},

		packageRoomOptions() {
			return this.$store.getters['travelapi/getRoomoptions'];
		},

		isRoomOptionAvailable() {
			return !!this.packageRoomOptions.find(option => option.id === this.data.data.packageOption.id);
		}
	},

	watch: {
		/*
		 * Wachter for cartstatus, allowed to checkout boolean.
		 */
		shopCart: {
			handler(val) {
				/*
				 * Show an error when the travel requirments are not met. Travel must be bought with tickets in certain sale types.
				 */
				if (
					val &&
					val.hasOwnProperty('checkoutAllowed') &&
					!val.checkoutAllowed &&
					this.data.key == 'travel' &&
					this.isRoomOptionAvailable &&
					!this.cartCheckoutErrorSeen
				) {
					const checkoutError = val.checkoutErrors.find(
						item => item.code === 'validation.travel.occupants.does.not.equal.number.entrance.tickets'
					);

					if (checkoutError) {
						this.cartCheckoutErrorSeen = true;

						let error = `<ul>`;

						for (let i = 0; i < val.checkoutErrors.length; i++) {
							error += `<li>${val.checkoutErrors[i].message}</li>`;
						}

						error += `</ul>`;

						this.$store.commit('setModalMessage', error);
						this.$fireErrorEvent(8000);
					}
				}
			},
			deep: true,
			immediate: true
		}
	},

	methods: {
		gotoRoute(e, route) {
			if (!route) {
				route = this.data.key === 'ticket' ? 'tickets' : this.data.key;
			}

			this.$store.commit('setModalPrefillCartActive', false);
			this.$store.commit('setShowCheckout', false);

			this.$router.push({
				name: `slug-${route}`,
				params: {
					slug: this.$route.params.slug
				}
			});
		},

		getMerchandiseWithSize(data) {
			const item = this.getMerchandise.find(item => item.id === data.type.id);
			if (item) {
				return `${item.name}: ${item.meta.vendorVariantTitle}`;
			}
		},

		getCountry(item) {
			return this.countryList.find(country => country.value === item.type.name.split('_')[0].toLowerCase()).type;
		},

		maxTickets() {
			return Math.min(this.shopConfig.maxTicketAmount, this.data?.data?.type?.maxQuantity);
		},

		addToCart(item) {
			this.$store.commit('shopapi/setShopUpdateState', 'updating');
			item = item === undefined ? this.data.data : item;
			let maxQuantity = this.maxTickets() || item.type.maxQuantity || undefined;
			const isTravelticket =
				item?.type?.categories?.length &&
				(item.type.categories[0].en === 'TRAVEL TICKETS' || item.type.categories[0].nl === 'TRAVEL TICKETS');

			if (!isTravelticket && (item.type.soldOut || item.type.stockAvailable <= 0)) {
				return;
			}
			const payload = {
				url: `${process.env.SHOP_API_BASE}/carts/${this.shopCart.id}/products`,
				params: {
					productId: item.type.id,
					quantity:
						item.type.mappedType == 'ticket' && maxQuantity
							? item.numproducts >= maxQuantity
								? maxQuantity
								: item.numproducts
							: item.numproducts
				}
			};

			const tempData = {
				name: item.type.meta.name || null,
				price: item.type.price || null,
				productId: item.type.id || null,
				amount:
					item.type.mappedType == 'ticket' && maxQuantity
						? item.numproducts >= maxQuantity
							? maxQuantity
							: item.numproducts
						: item.numproducts || null,
				shop: this.$store.getters['shopconfig/getData'].titleFull || null,
				type: item.type.mappedType || null
			};

			// this.addToDataLayer({
			// 	products: [tempData]
			// });
			// console.log(payload);
			// this.$store.commit('shopapi/setShopUpdateState', '');
			if (item.type.mappedType == 'ticket' && maxQuantity < item.numproducts) {
				this.$fireErrorEvent(5004);
			}

			this.$store.dispatch(`shopapi/putRequest`, payload).then(response => {
				if (response.data.hasOwnProperty('error')) {
					isTravelticket && this.$store.dispatch('shopapi/deleteTravelCart');
					this.hasError = true;
					this.failedResponse = `${response.data.error.message}`;
					this.apiResponse = $t('error.product_sold_out');
					return;
				}

				this.apiResponse = this.$t('label.cart.added');
			});
		},

		async loadTravel() {
			this.$store.commit('shopapi/setShopUpdateState', 'updating');

			const { id, occupation, startOfStayDate, endOfStayDate } = this.data.data.package;
			const roomOptionParams = {
				packageId: id,
				params: {
					persons: occupation,
					startOfStay: startOfStayDate,
					endOfStay: endOfStayDate
				}
			};

			await this.$store.dispatch('travelapi/loadSettings');
			await this.$store.dispatch('travelapi/loadFilters');
			await this.$store.dispatch('travelapi/loadPackages');
			await this.$store.dispatch('travelapi/queryRoomOptions', roomOptionParams);

			if (this.isRoomOptionAvailable) {
				this.addToTravelCart();
			} else {
				this.hasError = true;
				this.failedResponse = $t('error.product_sold_out');
				this.apiResponse = $t('error.product_sold_out');
				this.$store.commit('shopapi/setShopUpdateState', '');

				return;
			}
		},

		async addToTravelCart() {
			this.$store.commit('shopapi/setShopUpdateState', 'updating');

			const packageData = this.data.data;
			const tempData = {
				name: packageData.package.name || null,
				price: packageData.totalPrice || null,
				productId: `${packageData.package.eventId}_${packageData.package.accommodationId}` || null,
				amount: 1,
				shop: this.$store.getters['shopconfig/getData'].titleFull || null,
				type: 'travel'
			};
			// this.addToDataLayer({
			// 	products: [tempData]
			// });

			this.hasAddRoomFailed = false;
			const isNewRoom =
				!this.shopTravelCart || this.shopTravelCart.packageOption.id !== this.data.data.packageOption.id;

			if (isNewRoom) {
				// Only add room to cart if it wasn't already there (because we're only updating the extras of a booking)

				const addRoomToCartResult = await this.addRoomToCart();

				if (!addRoomToCartResult || !addRoomToCartResult.status || addRoomToCartResult.status !== 200) {
					let error = addRoomToCartResult.data.error.message;

					if (error === 'There is no stock for one of the rooms you want to book') {
						error =
							'At this moment, this product is not available. Try again later or choose a different product.';
					}

					this.hasError = true;
					this.failedResponse = error;
					this.apiResponse = 'This package combination is sold out.';

					return;
				}
			}

			for (let i = 0, numExtras = this.data.data.extras.length; i < numExtras; i++) {
				await this.addOrUpdateCartExtra(this.data.data.extras[i], this.data.data.extras[i].amount).catch(
					error => {
						this.hasError = true;
						this.failedResponse = response.data.error.message;
						this.apiResponse = 'This package combination is sold out.';
						return;
					}
				);
			}

			if (!this.travelEntrancetickets.length) {
				this.$store.dispatch('shopapi/updateCart');
				this.apiResponse = 'Added to cart';
			} else {
				this.addToCart(this.travelEntrancetickets[0]);
			}

			this.$store.dispatch('shopapi/updateTravelCart');
		},

		addRoomToCart() {
			const payload = {
				url: `${process.env.SHOP_API_BASE}/carts/${this.shopCart.id}/products/hotel/${this.shopConfig.apiTravelId}`,
				params: {
					productId: this.data.data.packageOption.id
				}
			};

			return this.$store.dispatch(`shopapi/putRequest`, payload);
		},

		addOrUpdateCartExtra(extra, amount, update = false) {
			const payload = {
				url: `${process.env.SHOP_API_BASE}/carts/${this.shopCart.id}/products/hotel/${this.shopConfig.apiTravelId}/extras`,
				params: {
					extraId: extra.extraId,
					amount
				}
			};

			return this.$store.dispatch('shopapi/postRequest', payload);
		}
	},

	mounted() {
		/*
		 * Check if selected travelticket is soldout
		 */
		if (this.data.key == 'travel' && this.travelEntrancetickets && this.travelEntrancetickets.length) {
			if (this.travelEntrancetickets[0].type.soldOut || this.travelEntrancetickets[0].type.stockAvailable <= 0) {
				this.hasError = true;
				this.failedResponse = 'This product is sold out.';
				this.apiResponse = 'This product is sold out.';
			}
		}
	}
};
</script>

<style lang="scss">
$component: 'prefill-cart-item';

$height: 35px;

.#{$component} {
	position: relative;
	min-height: $height;

	padding: 5px 0;

	> .grid {
		align-items: center;
	}

	&:nth-child(odd) {
		&:after {
			opacity: 0;
		}
	}

	&:after {
		content: '';
		display: block;
		position: absolute;

		width: calc(100% + 10px);
		height: 100%;

		left: 0;
		top: 0px;
		opacity: 1;

		margin-left: -10px;
		background: var(--content-background-light-2);

		z-index: 0;
	}

	&__spinner {
		height: 20px;

		> .lds-ellipsis {
			height: 20px !important;
			> div {
				top: 3px !important;
			}
		}
	}

	&__alternative {
		position: relative;
		min-height: $height;

		> .grid {
			// align-items: center;
			min-height: $height;
		}
	}

	&__list-value {
		position: relative;
		display: block;
		font-size: 14px;
		color: $color-grey;
		text-align: left;

		// white-space: nowrap;
		// text-overflow: ellipsis;
		// overflow: hidden;

		z-index: 2;

		&--white {
			color: $color-white;
		}

		&--red {
			color: $color-red;
			font-family: $font-medium;
		}

		&--green {
			color: $color-green;
			font-family: $font-medium;
		}
	}

	&__error {
		> span {
			color: $color-red;
			font-family: $font-medium;
		}
	}

	@include sm {
		&__selection {
			&:after {
				left: 10px;
				width: calc(100% + 30px);
			}
		}
	}
}
</style>
